import React from 'react';
import styled, { css } from 'styled-components';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';
import H1 from './typography/H1';
import P from './typography/P';
import data from '../data/data.json';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	width: 100%;
`;

const Subtitle = styled.div`
	font-size: 18px;
	font-family: ${({ theme }) => theme.fonts.semibold};
	color: #000;
`;

const ResultsContainer = styled.div`
	
`;

const Search = ({ textColor }) => {
	const { term } = useParams();
	const [errorCode, setErrorCode] = React.useState(term);
	const [dataSet] = React.useState(data);
	const { t } = useTranslation();

	const searchTermUpdateHandler = (term) => {
		setErrorCode(term === "" ? null : term);
	}

	return ( 
		<Wrapper>
			<H1 textColor={ textColor }>{ t("ui.search") }</H1>
			<P textColor = { errorCode !== null && errorCode !== undefined ? "transparent" : textColor }>{ t("ui.entercodetocontinue") }</P>
			<SearchBox value={ errorCode !== null && errorCode !== undefined ? errorCode : "" } onTextChange={searchTermUpdateHandler} />
			<ResultsContainer>
				{ 
				dataSet.codes.filter(dtc => dtc.code.toLowerCase().includes(!errorCode ? null : errorCode.toLowerCase()) || dtc.alias.toLowerCase().includes(!errorCode ? null : errorCode.toLowerCase()))
				//dataSet.codes.filter(dtc => dtc.code.toLowerCase() === (errorCode == null ? null : errorCode.toLowerCase()))
				.map(dtc => (
					<SearchResult key={ dtc.code } data={ dtc } code={dtc.code} description={dtc.description} severity={dtc.severity} />
				)) }
				<SearchResult errorCode={errorCode} />
			</ResultsContainer>
		</Wrapper>
	 );
}
 
export default Search;