import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: ${({ color }) => color ? color : "transparent"};
	z-index: ${({ zIndex }) => zIndex ? zIndex : "0"};
`;

const Mask = ({ color, onClick, zIndex, visible }) => {

	if (!visible){
		return null;
	}

	return ( 
		<Wrapper color={color} onClick={onClick} zIndex={zIndex} />
	 );
}
 
export default Mask;