import React from 'react';
import styled from 'styled-components';
import LanguageSelectBtn from '../LanguageSelectBtn';

const Wrapper = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-image: url('../images/background.jpg');
	background-repeat: no-repeat;
	background-position: fixed;
	background-size: cover;
	padding-top: 145px;
	overflow-y: auto;
`;

const Logo = styled.img`
	position: fixed;
	left: 0px;
	top: 30px;
`;

const HeroPage = ({ children }) => {
	return ( 
		<Wrapper>
			<Logo src={'/images/agco-logo-hero.png'} />
			<LanguageSelectBtn />
			{children}
		</Wrapper>
	 );
}
 
export default HeroPage;