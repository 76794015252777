import React from 'react';
import LanguageSelect from '../components/LanguageSelect';
import HeroPage from '../components/layout/HeroPage';
import HeroPageContentWrapper from '../components/layout/HeroPageContentWrapper';
import Search from '../components/Search';

const HeroHome = () => {

	return ( 
		<HeroPage>
			<LanguageSelect />
			<HeroPageContentWrapper>
				<Search textColor = { ({ theme }) => theme.colors.AGCOWhite } />
			</HeroPageContentWrapper>
		</HeroPage>
	 );
}
 
export default HeroHome;