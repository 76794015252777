const theme = {
	colors: {
		AGCORed: '#C00031',
		AGCOBlack: '#000000',
		AGCOGrayDark: '#2A2A2A',
		AGCOGrayMidLight: '#3e3e3e',
		AGCOGrayMid: '#4D4D4D',
		AGCOGrayLight: '#F0F0F0',
		AGCOGrayLightMidLight: '#D9D8D8',
		AGCOGrayHighlight: 'rgba(25, 25, 25, 0.25)',
		AGCOWhite: '#FFFFFF',
		attention: {
			note: '#002772',
		},
	},
	space: {
		xxl: '60px',
		xl: '40px',
		l: '30px',
		m: '24px',
		ms: '20px',
		s: '16px',
		xs: '6px',
		xxs: '4px',
		xxxs: '2px',
		attention: '10px 0px 10px 20px',
		table: '9px 20px',
	},
	fontSizes: {
		xxxxl: '72px',
		xxxl: '50px',
		xxl: '36px',
		xl: '26px',
		l: '24px',
		m: '18px',
		s: '16px',
		xs: '14px',
		xxs: '11px'
	},
	lineHeights: {
		xl: '31px',
		l: '28px',
		m: '24px',
		s: '20px',
		xs: '18px',
	},
	letterSpacings: {},
	fonts: {
		regular: 'Roboto',
		medium: 'Roboto-Medium',
		bold: 'Roboto-Bold',
		slabBold: 'RobotoSlab-Bold',
		slabExtraBold: 'RobotoSlab-ExtraBold',
		navigation: 'Arial',
	},
	radii: {
		s: '2px',
		m: '4px',
		l: '6px',
		xl: '16px',
		xxl: '20px',
	},
	shadows: {
		modal: '0 2px 20px 0 #575757',
	},
	borderWidths: {
		l: 4,
		m: 2,
		s: 1,
	},
	sizes: {
		contentContainer: '1000px',
	},
	screens: {
    md: '1025px',
    sm: '768px',
  },
};

export default theme;
