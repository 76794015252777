import React from 'react';
import styled from 'styled-components';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import Search from '../components/Search';


const Home = () => {
	return ( 
		<Page>
			<PageContentWrapper>
				<Search />
			</PageContentWrapper>
		</Page>
	 );
}
 
export default Home;