import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { languages } from '../data/languages';
import Mask from './Mask';

const Wrapper = styled.div`
	display: block;
	position: absolute;
	top: 43px;
	right: 0px;
	width: 750px;
	z-index: 10000;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	
	@media only screen and (max-width: ${({ theme }) => theme.screens.sm}) {
		max-height: 75vh;
		overflow-y: scroll;
		width: 90vw;
		position: fixed;
		left: 5vw;
		top: 60px;
	}
`;

const LanguagesList = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	padding: 10px;

	@media only screen and (max-width: ${({ theme }) => theme.screens.sm}) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const Language = styled.div`
	align-items: middle;
	padding-top: 8px;
	padding-bottom: 8px;
	cursor: pointer;
`;

const Flag = styled.img`
	display: inline;
	margin-top: -2px;
	margin-right: 19px;
`;

const LanguageSelect = ({ toggleSelector, visible }) => {

	const location = useLocation();
	const { i18n } = useTranslation();

	const handleChangeLanguage = (language) => {
		i18n.changeLanguage(language);
		
		toggleSelector(); // Hide the selector after the language change
	}

	if(!visible) {
		return null;
	}

	return ( 
		<> 
			<Mask zIndex="9999" visible={true} color={"rgba(0,0,0,0.5)"} onClick={toggleSelector}/>
			<Wrapper>
				<LanguagesList>
					{languages.map((language) => (
						<Language key={language.code} onClick={() => {handleChangeLanguage(language.code)}}>
							<Flag src={`/images/flags/flag-${language.code}.svg`} />
							{language.name}
						</Language>
					))}
				</LanguagesList>
			</Wrapper>
		</> 
	 );
}
 
export default LanguageSelect;